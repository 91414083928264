<template>
    <div class="my">
        <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh">
            <div class="my-wrap">
                <!-- 个人资料 -->
                <div class="my-wrap-top">
                    <div class="my-wrap-top-r">
                        <img src="../../assets/shezhi.png" class="shezhi" alt />
                        <router-link to="/personal" tag="p">个人资料</router-link>
                    </div>
                    <div class="my-wrap-top-l">
                        <div>
                            <img :src="userInfo.headimgurl" style="object-fit: cover;" alt />
                            <p class="position">{{ userTypeText }}</p>
                        </div>
                        <p class="yang">{{ userInfo.nickname }} </p>
                        <!-- <p class="yang">{{ userInfo.user_code }}</p> -->
                    </div>
                </div>
            </div>

            <!-- 推广员 -->
            <div class="my-wrap-integral1" v-if="userInfo.user_types == 2">
                <router-link to="/tanlent" tag="div">
                    <div>
                        <p><i>￥</i>{{ userStatistics.sum_wallet }}</p>
                        <p>可提现</p>
                    </div>
                    <span></span>
                    <div>
                        <p><i>￥</i>{{ userStatistics.total_wallet }}</p>
                        <p>累计</p>
                    </div>
                </router-link>
            </div>
            <!-- 订单列表 -->
            <div class="OrderList">
                <router-link to="/allorder?cur=0" tag="div" class="OrderList-top">
                    <p>我的订单</p>
                    <div>
                        <p>全部订单</p>
                        <svg t="1597993880468" class="icon" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" p-id="3161" width="16" height="16">
                            <path d="M400 876.8l339.2-339.2v-44.8L400 156.8l-44.8 44.8 313.6 313.6-313.6 316.8z"
                                fill="#d0d0d0" p-id="3162" />
                        </svg>
                    </div>
                </router-link>
                <ul class="OrderList-bottom">
                    <li>
                        <router-link to="/allorder?cur=0">
                            <p>
                                <img src="../../assets/dd03.png" alt />
                            </p>
                            <p>全部订单</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/allorder?cur=3">
                            <p>
                                <img src="../../assets/dd04.png" alt />
                            </p>
                            <p>待使用</p>
                            <p v-if="userStatistics.no_used > 0">{{ userStatistics.no_used }}</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/allorder?cur=4">
                            <p>
                                <img src="../../assets/dd05.png" alt />
                            </p>
                            <p>已完成</p>
                        </router-link>
                    </li>
                </ul>
            </div>

            <!-- 赚取饭佣 -->
            <div class="makeMoney" v-if="userInfo.user_types == 2">
                <div class="makeMoney-top">
                    <p>赚饭佣</p>
                </div>
                <ul class="makeMoney-bottom">
                    <li @click="inviteFriends">
                        <p><img :src="require('../../assets/yj01.png')" /></p>
                        <p>邀请好友</p>
                    </li>
                    <li>
                        <router-link to="/tanlent">
                            <p><img :src="require('../../assets/yj02.png')" /></p>
                            <p>掌柜工作台</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/myteam">
                            <p><img :src="require('../../assets/yj03.png')" /></p>
                            <p>我的团队</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/applypromoter?types=2">
                            <p><img :src="require('../../assets/yj03.png')" /></p>
                            <p>饭佣说明</p>
                        </router-link>
                    </li>
                </ul>
            </div>
            <!-- 常用工具 -->
            <div class="tool-wrap">
                <p style="font-size: 0.3rem">常用工具</p>
                <ul class="tool">
                    <li>
                        <router-link to="/ShopMallOrder">
                            <p><img :src="require('../../assets/gj01.png')" /></p>
                            <p>实物订单</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/AfterSaleOrder">
                            <p><img :src="require('../../assets/gj02.png')" /></p>
                            <p>售后订单</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/school">
                            <p><img :src="require('../../assets/gj03.png')" /></p>
                            <p>商学院</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/AddressList?from=user">
                            <p><img :src="require('../../assets/gj04.png')" /></p>
                            <p>收货地址</p>
                        </router-link>
                    </li>
                    <li @click="marckLogin">
                        <p><img :src="require('../../assets/gj05.png')" /></p>
                        <p>商家登录</p>
                    </li>
                    <li>
                        <router-link to="/newproblems?type=1">
                            <p><img :src="require('../../assets/gj06.png')" /></p>
                            <p>新手问答</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/newproblems?type=2">
                            <p><img :src="require('../../assets/gj07.png')" /></p>
                            <p>相关协议</p>
                        </router-link>
                    </li>

                    <li @click="PopupShow">
                        <p><img :src="require('../../assets/gj08.png')" /></p>
                        <p>平台客服</p>
                    </li>
                </ul>
            </div>

            <div @click="clearStorage" class="clear-btn">退出登录</div>
            <div class="footer">
                <p>版权所有：饭十荟 版本号：V1.3.10</p>
                <!-- <p>新晋时代提供技术支持</p> -->
            </div>
        </van-pull-refresh>
        <!-- 底部菜单 -->
        <tabbar :selectedId="selectedId"></tabbar>
    </div>
</template>

<script>
    import Tabbar from "@/components/Tabbar";
    import {
        getUser,
        getUserStatistics,
    } from "@/http/api";
    import {
        closeWindow
    } from "@/common/wxJssdk";

    export default {
        components: {
            Tabbar
        },
        data() {
            return {
                userToken: "",
                userInfo: {},
                userStatistics: {},
                popup: false,
                isLoading: false,
                selectedId: "4", //底部菜单参数
            };
        },
        mounted() {
            this.userInfo = this.$LStorage.getItem("userInfo")
            this.userToken = this.$LStorage.getItem("userToken").user_token;
            // 获取个人信息
            this.getUserInfo();
        },
        methods: {
            // 获取用户个人信息
            async getUserInfo() {
                const res = await getUser({
                    data: {
                        user_token: this.userToken
                    }
                });
                if (res.code == 200) {
                    this.userInfo = res.data;
                    this.$LStorage.setItem("userInfo", this.userInfo);
                    // 获取积分和收益
                    this.getUserStatistics();
                    return true;
                } else {
                    this.$dialog.alert({
                        message: "获取用户信息错误, 重新登录"
                    }).then(() => {
                        this.$LStorage.removeItem("userInfo");
                        this.$LStorage.removeItem("userToken");
                        this.$router.replace({
                            path: "/home"
                        });
                    })
                }
            },

            // 获取用户的积分和收益
            async getUserStatistics() {
                const res = await getUserStatistics({
                    data: {
                        user_token: this.userToken
                    }
                });
                this.userStatistics = res.data;
            },

            // 邀请好友
            inviteFriends() {
                if (this.userInfo.is_qrcode == 0) {
                    this.$store.dispatch("changeWechat", true);
                } else if (this.userInfo.is_qrcode == 1) {
                    this.$router.push({
                        path: "/inviteposter"
                    });
                }
            },
            
            // 打开遮罩
            PopupShow() {
                this.$store.dispatch("changeMakePhone", true);
            },
            
            // 刷新
            onRefresh() {
                if (this.getUserInfo()) {
                    this.$toast("刷新成功");
                    this.isLoading = false;
                }
            },

            // 清除缓存
            clearStorage() {
                this.$dialog.alert({
                    title: "提示",
                    message: "退出成功"
                }).then(() => {
                    this.$LStorage.removeItem("userInfo");
                    this.$LStorage.removeItem("userToken");
                    // 关闭
                    closeWindow();
                });
            },

            // 商家登陆判断
            marckLogin() {
                //  this.$LStorage.getItem("business")
                if (this.$LStorage.getItem("shopUserInfo")) {
                    this.$router.push({
                        path: "/merchantwork"
                    });
                } else {
                    this.$router.push({
                        path: "/merchatlogin"
                    });
                }
            }
        },
        computed: {
            userTypeText() {
                let e = this.userInfo.user_types;
                if (e == 1) {
                    return "普通用户";
                } else if (e == 2) {
                    if (this.userInfo.referrer_lv == 1) {
                        return "二掌柜";
                    } else if (this.userInfo.referrer_lv == 2) {
                        return "大掌柜";
                    }
                } else {
                    return "未知";
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .my {
        width: 100%;
        padding-bottom: 1.19rem;

        .my-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.3rem;
            box-sizing: border-box;
            font-size: 0.38rem;
            background: #f4f4f4;
        }

        .my-wrap {
            width: 100%;
            padding: 0.26rem 0.3rem 0.23rem 0.31rem;
            box-sizing: border-box;
            border-bottom: 0.28rem solid #f6f6f6;
            background: url(../../assets/mineback.jpg) no-repeat top;
            background-size: 100%;

            .my-wrap-top {
                .my-wrap-top-l {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    margin-top: -0.3rem;
                    // justify-content: center;

                    div:nth-child(1) {
                        position: relative;

                        img {
                            width: 1.48rem;
                            height: 1.48rem;
                            border-radius: 50%;
                            border: 0.03rem solid #fc6f15;
                        }

                        .position {
                            width: 1.38rem;
                            height: 0.4rem;
                            background: #fc6f15;
                            font-size: 0.24rem;
                            text-align: center;
                            line-height: 0.4rem;
                            color: #fff;
                            border-radius: 0.2rem;
                            position: absolute;
                            bottom: -0.05rem;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                            // transform: translateX(-50%);
                        }
                    }

                    .yang {
                        font-size: 0.32rem;
                        color: #fff;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        margin-left: 16px;
                    }
                }

                .my-wrap-top-r {
                    display: flex;
                    justify-content: flex-end;
                    height: 16px;
                    color: #fff;

                    img {
                        width: 0.3rem;
                        height: 0.3rem;
                        margin-right: 5px;
                    }

                    p {
                        font-size: 0.24rem;
                    }
                }
            }

            .my-wrap-integral {
                width: 100%;
                margin-top: 0.39rem;

                .my-wrap-integral-p {
                    width: 100%;
                    height: 1.34rem;
                    background: url("../../assets/bg.png") no-repeat center;
                    background-size: cover;
                    font-size: 0.24rem;
                    color: #fff;
                    text-align: center;
                    border-radius: 0.07rem;
                    padding-top: 0.2rem;
                    box-sizing: border-box;

                    p:nth-child(2) {
                        margin-top: 0.2rem;
                        font-size: 0.36rem;
                    }
                }
            }
        }

        .my-wrap-integral1 {
            width: 90%;
            height: 1.35rem;
            margin: 0 auto;
            border-bottom: 0.28rem solid #f6f6f6;
            background: url("../../assets/mineback.png") no-repeat;
            background-size: 100% 100%;

            div {
                width: 80%;
                height: 100%;
                float: right;
                display: flex;
                align-items: center;
                justify-content: space-between;

                div {
                    width: 49%;
                    height: 100%;
                    padding: 0.16rem 0;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;

                    p:nth-child(1) {
                        text-align: center;
                        color: #fff;
                        font-size: 0.36rem;

                        i {
                            font-size: 0.03rem !important;
                        }
                    }

                    p:nth-child(2) {
                        text-align: center;
                        color: #fff;
                        font-size: 0.2rem;
                    }
                }

                span {
                    width: 1px;
                    height: 60%;
                    background: rgba(243, 242, 242, 0.6);
                }
            }
        }

        .OrderList {
            width: 100%;
            padding: 0.37rem 0.3rem 0.37rem 0.28rem;
            box-sizing: border-box;
            border-bottom: 0.28rem solid #f6f6f6;

            .OrderList-top {
                display: flex;
                justify-content: space-between;
                font-size: 0.3rem;

                div {
                    display: flex;
                    align-items: center;

                    p {
                        color: #d0d0d0;
                        font-size: 0.26rem;
                    }
                }
            }

            .OrderList-bottom {
                width: 100%;
                display: flex;
                justify-content: space-around;
                padding-top: 0.4rem;

                li {
                    width: 25%;
                    position: relative;

                    img {
                        width: 0.49rem;
                        height: 0.47rem;
                    }

                    p {
                        font-size: 0.24rem;
                        text-align: center;
                        color: #333;
                    }

                    p:nth-child(2) {
                        margin-top: 0.23rem;
                    }

                    p:nth-child(3) {
                        position: absolute;
                        top: -0.05rem;
                        right: 0.2rem;
                        color: #fff;
                        background: #ea0501;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        font-size: 12px;
                    }
                }
            }
        }

        //普通用户
        .details-wrap {
            width: 100%;
            height: 1.5rem;
            position: relative;
            border-bottom: 0.27rem;

            img {
                width: 100%;
                height: 1.5rem;
            }

            p {
                width: 1.2rem;
                height: 0.37rem;
                font-size: 0.2rem;
                background: #fff;
                color: #5a6ffc;
                position: absolute;
                bottom: 0.07rem;
                left: 1.32rem;
                border-radius: 0.15rem;
                text-align: center;
                line-height: 0.37rem;
            }
        }

        //会员
        .makeMoney {
            width: 100%;
            padding: 0.37rem 0.3rem 0.37rem 0.28rem;
            box-sizing: border-box;
            border-bottom: 0.28rem solid #f6f6f6;

            .makeMoney-top {
                display: flex;
                justify-content: space-between;
                font-size: 0.3rem;

                div {
                    display: flex;
                    align-items: center;

                    p {
                        color: #d0d0d0;
                        font-size: 0.26rem;
                    }
                }
            }

            .makeMoney-bottom {
                width: 100%;
                display: flex;
                padding-top: 0.4rem;

                li {
                    width: 25%;
                    position: relative;

                    img {
                        width: 0.49rem;
                        height: 0.47rem;
                    }

                    p {
                        font-size: 0.23rem;
                        text-align: center;
                    }

                    p:nth-child(2) {
                        margin-top: 0.23rem;
                    }

                    p:nth-child(3) {
                        position: absolute;
                        top: -0.05rem;
                        right: 0.2rem;
                        color: #ea0501;
                    }
                }
            }
        }

        .tool-wrap {
            width: 100%;
            padding: 0.32rem 0.3rem 0.43rem 0.44rem;
            box-sizing: border-box;

            p {
                font-size: 0.23rem;
            }

            .tool {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                margin-top: 0.39rem;

                li {
                    width: 25%;
                    margin-bottom: 0.55rem;

                    img {
                        // width: 0.53rem;
                        height: 0.53rem;
                    }

                    p {
                        font-size: 0.23rem;
                        margin-top: 0.21rem;
                        text-align: center;
                    }
                }
            }
        }

        .footer {
            width: 100%;
            height: 1.73rem;
            background: #f6f6f6;
            font-size: 0.23rem;
            text-align: center;
            color: #d1d1d1;
            padding-top: 0.8rem;
            box-sizing: border-box;

            p:nth-child(2) {
                margin-top: 0.16rem;
            }
        }

        .clear-btn {
            font-size: 0.32rem;
            color: #fff;
            background-color: #fc6f15;
            margin: 0.4rem;
            height: 0.88rem;
            line-height: 0.88rem;
            border-radius: 0.44rem;
            text-align: center;
        }
    }
</style>
